.NoteListInlineEditorRow {
  grid-column-end: span 4;
  display: grid;
  grid-template-rows: auto auto;
  align-items: center;
  padding-right: 8px;
  min-height: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
  grid-row-gap: 8px;
}

input.NoteTextEntry {
  margin-left: 8px;
  margin-right: 3px;
}

.NoteListInlineEditorRow button {
  margin-left: 4px;
}

.NoteListInlineEditorRow button {
  margin-left: 4px;
}

.NoteListInlineEditorRow button.delete {
  margin-left: 10px;
}

.NoteListInlineEditorRow .Buttons {
  display: grid;
  grid-template-columns: auto 1fr auto auto;
}
