div.Track {
  margin: 18px;
}

.Track button.TrackListButton {
  float: left;
  margin-right: 16px;
  width: 40px;
  height: 40px;
}

.Track h1 {
  font-size: 2em;
  overflow: hidden;
  white-space: nowrap;
  text-align: left;
  text-overflow: ellipsis;
  margin-bottom: 0px;
}

.Track .TrackTitle {
  display: grid;
  grid-template-columns: auto 1fr auto;
  height: 40px;
}

.Track .TrackTitle {
  display: grid;
  grid-template-columns: auto 1fr auto;
}

.Track .TrackTitle .ShareButton {
  margin-left: 16px;
}

.Loading {
  margin: 15px;
}
