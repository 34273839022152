.LoginPane {
  margin: 20px auto;
  max-width: 320px;
}

.LoginPane h1 {
  text-align: center;
  margin-bottom: 20px;
}

.LoginPane form {
}

.LoginPane .Separator {
  margin-top: 20px;
  margin-bottom: 20px;
  font-style: italic;
}
