.Navigation img.logo-image {
  margin-right: 10px;
  border-radius: 4px;
}

.Navigation {
  background-color: white;
}

.Navigation.navbar {
  margin-left: 2px;
  background-color: white;
  border-bottom-color: rgb(200, 200, 200);
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.Navigation a.navbar-brand {
  align-items: center;
  display: flex;
  font-size: 1.9rem;
  font-weight: 500;
  padding-top: 8px;
  padding-bottom: 8px;
}

.Navigation a.navbar-brand span {
  margin-left: 6px;
}
