.SplitView {
  display: flex;
  flex-direction: row;
  flex: 1;
}

.SplitViewColumn {
  position: relative;
  float: left;
}

.SplitViewColumn.Left {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  justify-self: stretch;
}

.SplitViewColumn.Right {
}

.SplitView .container-fluid {
  padding-left: 0px;
  padding-right: 0px;
  display: flex;
  flex-direction: row;
}

.Row {
  flex: 1;
}
