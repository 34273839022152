.SharedTrackLanding .lander {
  padding: 80px 0;
  text-align: center;
}

.SharedTrackLanding h1 {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  padding-top: 20px;
  font-size: 2rem;
}

.SharedTrackLanding .lander p {
  color: #999;
}

.container {
  display: flex;
  flex-direction: column;
  font-family: sans-serif;
}

.container > p {
  font-size: 1rem;
}

.container > em {
  font-size: 0.8rem;
}
