.ShareSheetBody {
  display: grid;
  grid-template-rows: auto auto auto;
  grid-row-gap: 15px;
}

.ShareURL {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-column-gap: 8px;
}
