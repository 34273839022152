.SignUpPane {
  max-width: 320px;
  margin: 20px auto;
}

.SignUpPane form {
  margin: 20px auto;
}

.SignUpPane h1 {
  text-align: center;
  margin-bottom: 20px;
}

.SignUpPane form span.help-block {
  font-size: 14px;
  padding-bottom: 10px;
  color: #999;
}

.SignUpPane button {
}

.SignUpPane .ContinueButtons {
  /* margin-top: 20px; */
}

.SignUpPane .Separator {
  margin-top: 20px;
  margin-bottom: 20px;
  font-style: italic;
}
