div.App {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

h1 {
  text-align: left;
  font-size: 1.8rem;
}

.Separator {
  display: flex;
  align-items: center;
  text-align: center;
}
.Separator::before,
.Separator::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid #000;
}
.Separator::before {
  margin-right: 0.25em;
}
.Separator::after {
  margin-left: 0.25em;
}
