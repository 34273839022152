div.outer {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}

div.inner {
}

.centerCell {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.inner h1 {
  text-align: center;
}

.buttonBar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
}
