.NoteListAddNoteControls {
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-column-gap: 8px;
  /* margin-left: 8px;
  margin-right: 8px; */
}

.NoteListAddNoteControls > div {
  display: grid;
  align-items: center;
}

.NoteListAddNoteControls .thumb-buttons {
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 2px;
}

.NoteListAddNoteControls .text-input {
  grid-template-columns: 1fr auto;
  grid-column-gap: 2px;
}

.NoteListAddNoteControls .text-input > input {
  margin-left: 4px;
  min-width: 0px;
}

/* .NoteListAddNoteControls .thumb-buttons > button {
  margin-left: 10px;
} */
