.SignInWithApple,
.SignInWithApple:hover,
.SignInWithApple:focus,
.SignInWithApple:active,
.SignInWithApple.active {
  width: 100%;
  border-color: black;
  background-color: black;
  margin-bottom: 1rem;
}
