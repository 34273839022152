.NoteList {
  text-align: left;
}

/* Grid of Open and Done Notes */

.NoteListGrid {
  margin-top: 20px;
  width: 100%;
  display: grid;
  grid-template-columns: auto auto 1fr auto;
  border-top: 1px rgb(190, 190, 190) solid;
}

.NoteListRow {
  display: contents;
}

.NoteListRow > div {
  border-bottom: 1px rgb(240, 240, 240) solid;
  min-height: 70px;
  padding-top: 4px;
  padding-bottom: 4px;
}

.NoteListRow:nth-child(even) > div {
  background-color: rgba(0, 0, 0, 0.02);
}

.NoteListRow:hover > div {
  background-color: rgb(222, 226, 252);
}

.NoteList .checkbox {
  display: flex;
  align-items: center;
  padding-left: 8px;
}

.NoteList .checkbox input {
  margin-right: 20px;
  margin-left: 12px;
}

.NoteList .avatar {
  display: flex;
  align-items: center;
}

.NoteList .avatar > div {
  margin-right: 15px;
}

.NoteList .labels {
  display: grid;
  align-items: center;
  grid-template-rows: 1fr auto auto 1fr;
  overflow: hidden;
  padding-right: 15px;
}

.NoteList .labels .location {
  display: flex;
  font-size: 0.8em;
}

.NoteList .labels .description {
  display: flex;
}

.NoteList .buttons {
  display: grid;
  grid-template-columns: 44px 44px;
  align-items: center;
  column-gap: 2px;
}

.NoteList button {
  margin-left: 4px;
}

.NoteList .SmallButton {
  /* margin-left: 6px; */
}

/* Specific Styles for DoneNotes */

.DoneNotes {
  margin-top: 70px;
  border-top: 1px rgba(190, 190, 190, 0.5) solid;
}

.DoneNotes .NoteListRow > div {
  text-decoration: line-through;
  color: rgb(190, 190, 190);
}

.DoneNotes .NoteListRow > div.buttons {
  opacity: 1;
}
