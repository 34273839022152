.TrackList {
  display: flex;
  flex: 1;
  flex-direction: column;
  border-right-color: rgb(200, 200, 200);
  border-right-width: 1px;
  border-right-style: solid;
}

.EmptyTrackList {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  padding: 40px;
}

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-align: left;
  text-overflow: ellipsis;
}

.ellipsis.head {
  direction: rtl;
  text-overflow: ellipsis;
}

.TrackList button {
  margin-left: 4px;
}

.TrackListGrid {
  width: 100%;
  display: grid;
  grid-template-columns: auto 1fr;
}

.TrackListGrid .avatar {
  padding-left: 15px;
  padding-right: 15px;
  display: grid;
  align-items: center;
  justify-items: center;
}

.TrackListGrid .NoteCount {
  height: 40px;
  width: 40px;
  border-radius: 20px;
  background-color: rgb(240, 238, 240);
  display: grid;
  align-items: center;
}

.TrackListGrid .NoteCount.NonZeroCount {
  color: blue;
  font-weight: 500;
}

.TrackListGrid .NoteCount.UnknownCount {
  display: none;
}

.TrackListGrid .NoteCount.ZeroCount {
  color: grey;
}

.TrackListGrid .labels-and-buttons {
  display: grid;
  grid-template-columns: 1fr auto;
}

.TrackListGrid .labels {
  display: grid;
  align-items: center;
  grid-template-rows: 1fr auto auto 1fr;
  overflow: hidden;
}

.TrackListGrid .inlineEdit {
  /* grid-column-start: 2;
  grid-column-end: span 2; */
  display: grid;
  grid-template-columns: 1fr auto auto;
  align-items: center;
  padding-right: 8px;
}

.TrackListGrid .inlineEdit input {
  min-width: 0px;
}

.TrackListGrid .uploadProgress {
  display: grid;
  align-items: center;
  grid-template-rows: 1fr auto auto 1fr;
  overflow: hidden;
  /* grid-column-start: 2;
  grid-column-end: span 2; */
}

.TrackListGrid .buttons {
  padding-right: 8px;
  padding-left: 2px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
}

.TrackListTableRow {
  display: contents;
}

.TrackListTableRow > div {
  min-height: 66px;
}

.TrackListTableRow > div {
  border-bottom: 1px rgb(230, 230, 230) solid;
}

.TrackListTableRow:nth-child(even) > div {
  background-color: rgba(0, 0, 0, 0.02);
}

.TrackListTableRow:nth-child(even) > div.selected {
  background-color: rgba(20, 20, 200, 0.1);
}

.TrackListTableRow:nth-child(odd) > div.selected {
  background-color: rgba(20, 20, 255, 0.1);
}
.TrackListTableRow:hover > div,
.TrackListTableRow:hover > div.selected {
  background-color: rgb(222, 226, 252);
}
