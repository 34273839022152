.MixUploadDropZone .dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  outline: none;
  transition: border 0.24s ease-in-out;
}

.MixUploadDropZone .dropzone p.uploadExplainer {
  padding: 10px;
  color: #7d7d7d;
  margin-bottom: 0px;
  border-width: 1px;
  border-style: dashed;
  border-color: rgba(0, 0, 0, 0.2);
}

.emptyTrackList {
  font-size: 20px;
}

.MixUploadDropZone .dropzone:focus {
  border-color: #2196f3;
}

.MixUploadDropZone .dropzone.disabled {
  opacity: 0.6;
}
